
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin-blog::v-deep {
    table {
        border-collapse: collapse;

        tr > td:first-child {
            font-weight: 600;
        }

        td,
        th {
            padding: 5px 8px;
            vertical-align: top;
            border: 2px solid #eee;
        }
    }
}
